<template>
  <b-row>
    <b-col
      md="4"
      class="align-self-center pl-3"
    >
      <b-button
        v-if="hasPermission(`${module}.new`)"
        variant="success"
        size="sm"
        class="bg-daren-2"
        :disabled="isDisabled"
        @click="() => $emit('creating')"
      >
        <feather-icon icon="PlusIcon" />
        Nuevo
      </b-button>
    </b-col>
    <b-col
      md="8"
      class="my-1 pr-3"
    >
      <b-form-group
        label="Buscar"
        label-cols-sm="3"
        label-align-sm="right"
        label-for="filterInput"
        class="mb-0 w-75 float-right"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder=""
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Limpiar
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-table
        striped
        hover
        small
        responsive
        :fields="fields"
        :items="records"
        :filter="filter"
        :per-page="dataPerPage"
        :current-page="pagination.currentPage"
        :busy="busy"
        show-empty
        :filter-included-fields="filterOn"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Cargando datos...</strong>
          </div>
        </template>
        <template #empty>
          <h4 class="text-center py-2">
            No hay registros
          </h4>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <b-button
              v-if="hasPermission(`${module}.details`)"
              v-b-tooltip.hover.top="'Detalle'"
              variant="info"
              class="btn-icon mr-25"
              size="sm"
              @click.prevent="() => $emit('detail', data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-if="hasPermission(`${module}.edit`)"
              v-b-tooltip.hover.top="'Editar'"
              variant="primary"
              class="btn-icon mr-25"
              size="sm"
              @click.prevent="() => $emit('editing', data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-if="hasPermission(`${module}.delete`)"
              v-b-tooltip.hover.top="'Eliminar'"
              variant="danger"
              class="btn-icon mr-25"
              size="sm"
              @click.prevent="() => $emit('deleting', data.item)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-col>
    <b-col
      md="6"
      class="pl-3"
    >
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Páginas</label>
        <b-form-select
          v-model="pagination.perPage"
          size="sm"
          :options="pagination.pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="6"
      class="pr-3 pb-2"
    >
      <b-pagination
        v-model="pagination.currentPage"
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        align="right"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
	BTable,
	BCol,
	BRow,
	BFormGroup,
	BFormInput,
	BInputGroup,
	BInputGroupAppend,
	BButton,
	BPagination,
	BFormSelect,
	BSpinner,
	VBTooltip,
} from 'bootstrap-vue'
import PermissionMixin from '@/mixins/PermissionMixin'

export default {
	name: 'BaseTable',
	components: {
		BTable,
		BCol,
		BRow,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BInputGroupAppend,
		BButton,
		BPagination,
		BFormSelect,
		BSpinner,
	},
	directives: {
		'b-tooltip': VBTooltip,
	},
	mixins: [PermissionMixin],
	props: {
		records: {
			type: Array,
			default: () => [],
		},
		busy: {
			type: Boolean,
			default: false,
		},
		isDisabled: {
			type: Boolean,
			default: true,
		},
		module: {
			type: String,
			default: null,
		},
    search: {
      type: String,
      default: null,
    },
	},
	data() {
		return {
			filter: this.search,
			pagination: {
				perPage: 10,
				pageOptions: [10, 20, 50],
				totalRows: 1,
				currentPage: 1,
			},
			filterOn: [],
			sortDesc: false,
			sortDirection: 'asc',
			fields: [
				{
					key: 'mac',
					label: 'Mac de dispositivo',
				},
				{
					key: 'device_type_name',
					label: 'Tipo de dispositivo',
				},
				{
					key: 'status',
					label: 'Estado',
				},
				{
					key: 'brand_name',
					label: 'Sede',
				},
				{
					key: 'subarea_name',
					label: 'Subarea',
				},
				{
					key: 'location_name',
					label: 'Ubicación',
				},
				{
					key: 'actions',
					label: 'Acciones',
				},
			],
		}
	},
	computed: {
		dataPerPage() {
			const me = this
			return me.paginate ? me.pagination.perPage : me.records.length
		},
	},
  watch: {
    search(newData) {
      this.filter = newData
    },
  },
	mounted() {
		this.pagination.totalRows = this.records.length
	},
	methods: {
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.pagination.currentPage = 1
		},
	},
}
</script>

<style lang="scss" scoped></style>
