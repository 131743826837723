<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Nombre de la sede*"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="formData.name"
            placeholder="Nombre de la sede"
            @blur="$v.formData.name.$touch()"
          />
          <span
            v-if="$v.formData.name.$error && !$v.formData.name.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Código de habilitación*"
          label-for="enabling_code"
        >
          <b-form-input
            id="enabling_code"
            v-model="formData.enabling_code"
            placeholder="Código de habilitación"
            @blur="$v.formData.enabling_code.$touch()"
          />
          <span
            v-if="$v.formData.enabling_code.$error && !$v.formData.enabling_code.required"
            class="help-block text-danger"
          >Campo requerido</span>
          <span
            v-if="$v.formData.enabling_code.$error && !$v.formData.enabling_code.numeric"
            class="help-block text-danger"
          >Ingrese solo números.</span>
          <span
            v-if="
              $v.formData.enabling_code.$error &&
                !$v.formData.enabling_code.maxLength
            "
            class="help-block text-danger"
          >
            El Código debe tener máximo
            {{ $v.formData.enabling_code.$params.maxLength.max }} dígitos.
          </span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Dirección*"
          label-for="address"
        >
          <b-form-input
            id="address"
            v-model="formData.address"
            placeholder="Dirección"
            @blur="$v.formData.address.$touch()"
          />
          <span
            v-if="$v.formData.address.$error && !$v.formData.address.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="País*"
          label-for="country"
        >
          <v-select
            id="country"
            v-model="country_id"
            :options="countries"
            :reduce="country => country.id"
            label="name"
            disabled
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Departamento*"
          label-for="departament"
        >
          <v-select
            id="departament"
            v-model="formData.department_id"
            :options="departaments"
            :reduce="departament => departament.id"
            label="name"
            placeholder="Seleccione departamento"
            @input="loadCities"
            @blur="$v.formData.department_id.$touch()"
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
          <span
            v-if="$v.formData.department_id.$error && !$v.formData.department_id.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Ciudad*"
          label-for="city"
        >
          <v-select
            id="city"
            v-model="formData.city_id"
            :options="cities"
            :reduce="city => city.id"
            label="name"
            :disabled="isDisable"
            placeholder="Seleccione ciudad"
            @blur="$v.formData.city_id.$touch()"
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
          <span
            v-if="$v.formData.city_id.$error && !$v.formData.city_id.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Zona"
          label-for="zone"
        >
          <b-form-input
            id="zone"
            v-model="formData.zone"
            placeholder="Zona"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Latitud*"
          label-for="latitude"
        >
          <b-form-input
            id="latitude"
            v-model="formData.latitude"
            placeholder="Latitud"
          />
          <span
            v-if="$v.formData.latitude.$error && !$v.formData.latitude.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Longitud*"
          label-for="longitude"
        >
          <b-form-input
            id="longitude"
            v-model="formData.longitude"
            placeholder="Longitud"
          />
          <span
            v-if="$v.formData.longitude.$error && !$v.formData.longitude.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup,
  BCol,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import {
 required, numeric,
	maxLength,
} from 'vuelidate/lib/validators'
import vSelect from 'vue-select'

export default {
  inject: ['headquarterRepository'],
  components: {
    BFormGroup,
    BCol,
    BFormInput,
    BRow,
    vSelect,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        name: '',
        address: '',
        enabling_code: '',
        city_id: null,
        latitude: '',
        longitude: '',
        zone: '',
        department_id: null,
        is_principal: false,
      },
      country_id: 1,
      countries: [],
			departaments: [],
			cities: [],
			isDisable: true,
    }
  },
  validations: {
    formData: {
      name: {
        required,
      },
      address: {
        required,
      },
      enabling_code: {
        required,
        numeric,
        maxLength: maxLength(20),
      },
      city_id: {
        required,
      },
      department_id: {
        required,
      },
      latitude: {
        required,
      },
      longitude: {
        required,
      },
    },
  },
  async created() {
    const me = this
    await me.loadCountries()
		await me.loadDepartaments()
  },
  async mounted() {
    const me = this
    if (me.record) await me.loadCities(me.record.department_id)
    me.formData = { ...me.formData, ...me.record }
  },
  methods: {
    validate() {
      const me = this
      me.$v.formData.$touch()
      const isValid = !me.$v.formData.$invalid

      me.$emit('on-validate', me.formData, isValid)
      return isValid
    },
    async loadCountries() {
			const me = this
			try {
				const res = await me.headquarterRepository.getAllCountry()
				me.countries = res.data
			} catch (error) {
				// console.log(error)
			}
		},
		async loadDepartaments() {
			const me = this
			try {
				const res = await me.headquarterRepository.getAllDepartament()
				me.departaments = res.data
			} catch (error) {
				// console.log(error)
			}
		},
		async loadCities(id) {
			const me = this
			try {
        me.formData.city_id = null
				me.isDisable = true
				const res = await me.headquarterRepository.getAllCitiesByDepartament(id)
				me.cities = res.data
			} catch (error) {
				me.isDisable = true
				// console.log(error)
			} finally {
				me.isDisable = false
			}
		},
  },
}
</script>

<style lang="scss" scoped></style>
