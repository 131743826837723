<template>
  <b-row>
    <b-col
      md="12"
      class="mb-1"
    >
      <div class="title">
        <strong>INFORMACIÓN DE LA EMPRESA</strong>
      </div>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Nombre de la empresa"
        label-for="name_company"
      >
        <b-form-input
          v-if="record"
          id="name_company"
          v-model="record.name_company"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Nit"
        label-for="nit"
      >
        <b-form-input
          v-if="record"
          id="nit"
          v-model="record.nit"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="N° de sedes permitidas"
        label-for="number_central_offices"
      >
        <b-form-input
          v-if="record"
          id="number_central_offices"
          v-model="record.number_central_offices"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="N° de usuarios permitidos"
        label-for="number_users"
      >
        <b-form-input
          v-if="record"
          id="number_users"
          v-model="record.number_users"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col
      md="12"
      class="mb-1"
    >
      <div class="title">
        <strong>INFORMACIÓN SEDE PRINCIPAL</strong>
      </div>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Nombre de la sede"
        label-for="name_brand"
      >
        <b-form-input
          v-if="record"
          id="name_brand"
          v-model="record.name_brand"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Código de habilitación"
        label-for="enabling_code"
      >
        <b-form-input
          v-if="record"
          id="enabling_code"
          v-model="record.enabling_code"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Dirección"
        label-for="address"
      >
        <b-form-input
          v-if="record"
          id="address"
          v-model="record.address"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Teléfono"
        label-for="phone"
      >
        <b-form-input
          v-if="record"
          id="phone"
          v-model="record.phone"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Celular"
        label-for="celphone"
      >
        <b-form-input
          v-if="record"
          id="celphone"
          v-model="record.celphone"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Contacto"
        label-for="contact"
      >
        <b-form-input
          v-if="record"
          id="contact"
          v-model="record.contact"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Cargo"
        label-for="post"
      >
        <b-form-input
          v-if="record"
          id="post"
          v-model="record.post"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Correo electrónico"
        label-for="email"
      >
        <b-form-input
          v-if="record"
          id="email"
          v-model="record.email"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Departamento"
        label-for="department_name"
      >
        <b-form-input
          v-if="record"
          id="department_name"
          v-model="record.department_name"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Ciudad"
        label-for="city_name"
      >
        <b-form-input
          v-if="record"
          id="city_name"
          v-model="record.city_name"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Zona"
        label-for="zone"
      >
        <b-form-input
          v-if="record"
          id="zone"
          v-model="record.zone"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Latitud"
        label-for="latitude"
      >
        <b-form-input
          v-if="record"
          id="latitude"
          v-model="record.latitude"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Longitud"
        label-for="longitude"
      >
        <b-form-input
          v-if="record"
          id="longitude"
          v-model="record.longitude"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col
      v-if="record && record.name_brand"
      md="12"
      class="text-center mt-1"
    >
      <b-button
        variant="outline-primary"
        class="btn-icon"
        @click="handleEditing(record.id)"
      >
        <feather-icon
          icon="EditIcon"
        /> Editar
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
	BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

export default {
	components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
	},
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  methods: {
    handleEditing(id) {
			this.$router.push({ name: 'client-update', params: { uuid: id } })
		},
  },
}
</script>
<style lang="scss" scoped>
  .title {
    font-size: 16px;
    font-weight: 700;
    color: #636363;
  }
</style>
