<template>
  <b-row>
    <b-col cols="12">
      <b-card-title class="mb-2">
        SERVICIOS
      </b-card-title>
    </b-col>
    <b-col cols="12">
      <b-row v-if="records.length > 0">
        <b-col
          v-for="(item, index) in records"
          :key="index"
          cols="12"
        >
          <headquarter-list
            :item="item"
            :busy="busy"
            @create="create"
            @edit="edit"
            @deleteItem="deleteItem"
            @redirectArea="redirectArea"
          />
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col
          md="12"
          class="text-center p-4"
        >
          <div class="box_sin_register p-2">
            Sin servicios por mostrar.
          </div>
        </b-col>
      </b-row>
    </b-col>

    <b-modal
      id="create-edit-service-modal"
      title="Nuevo Servicio"
      hide-footer
    >
      <CreateEditServiceForm
        :is-busy="isBusy"
        :record="record"
        @save="save"
      />
    </b-modal>
  </b-row>
</template>

<script>
import {
 BRow, BModal, BCol, VBTooltip, BCardTitle,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import HeadquarterList from './HeadquarterList'
import CreateEditServiceForm from './addForm/CreateEditServiceForm'

export default {
	inject: ['servicesRepository'],
	directives: {
		'b-tooltip': VBTooltip,
	},
	components: {
		BRow,
		BModal,
		BCol,
		BCardTitle,
		HeadquarterList,
		CreateEditServiceForm,
	},
	props: {
		records: {
			type: Array,
			default: () => [],
		},
		customerId: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			busy: false,
			isBusy: false,
			record: null,
		}
	},
	methods: {
		create(brandId) {
			const me = this
			me.record = null
			me.brandId = brandId
			this.$bvModal.show('create-edit-service-modal')
		},
		edit(item) {
			const me = this
			me.record = item
			this.$bvModal.show('create-edit-service-modal')
		},
		async save(data) {
			const me = this
			try {
				me.isBusy = true
				const brandId = data.brand_id ? data.brand_id : me.brandId
				const uuid = data.id ? data.id : uuidv4()
				const option = data.id ? 'update' : 'save'
				await me.servicesRepository[option](
					brandId,
					uuid,
					data,
				)

				const service = {
					...data,
					brand_id: brandId,
					id: uuid,
					service_name: data.name,
				}

				me.records.forEach(el => {
					if (el.id == brandId) {
						el.Service.forEach((servi, index) => {
							if (servi.id == uuid) {
								el.Service.splice(index, 1)
								el.Service.push(service)
							}
						})

						if (option == 'save') {
							el.Service.push(service)
						}
					}
				})

				this.$bvModal.hide('create-edit-service-modal')
			} catch (error) {
				// console.log(error)
			} finally {
				me.isBusy = false
			}
		},
		redirectArea(item) {
			const me = this
			me.$router.push({
				name: 'areas',
				params: {
					customerId: me.customerId,
					brandId: item.brand_id,
					serviceId: item.id,
				},
			})
		},
		async deleteItem(item) {
			const me = this
			this.$bvModal
				.msgBoxConfirm('¿Está seguro que desea eliminar el registro?', {
					title: 'Eliminar registro',
					size: 'sm',
					okVariant: 'danger',
					okTitle: 'Eliminar',
					buttonSize: 'sm',
					hideHeaderClose: false,
					cancelTitle: 'Cancelar',
					cancelVariant: 'outline-secondary',
					centered: true,
					modalClass: 'modal-danger',
				})
				.then(async value => {
					if (value) {
						me.isBusy = true
						try {
							await me.servicesRepository.delete(
								item.brand_id,
								item.id,
							)

							me.records.forEach(el => {
								if (el.id == item.brand_id) {
									el.Service.forEach((servi, index) => {
										if (servi.id == item.id) {
											el.Service.splice(index, 1)
										}
									})
								}
							})
						} catch (e) {
							console.error(e)
							me.$bvToast.toast('Error', {
								title: 'Ocurrió un error al eliminar el registro',
								toaster: 'b-toaster-bottom-right',
								variant: 'danger',
								solid: true,
								appendToast: true,
							})
						} finally {
							me.isBusy = false
						}
					}
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.btn {
	padding: 15px;
	position: relative;
	margin-top: 11px;
	z-index: 2;
}
.boton {
	width: 100%;
}
.iconos {
	padding: 8px;
	margin-left: 5px;
}
h4 {
	margin-left: 30px;
}
.box_sin_register {
	border: 4px solid #ea5455;
	color: #5e5873;
	font-size: 16px;
	border-radius: 0.4rem;
}
</style>
