import { v4 as uuidv4 } from 'uuid'

export default {
	data() {
		return {
			isProcessing: false,
			customer_id: JSON.parse(localStorage.getItem('user')).customer_id,
			paginationServe: null,
			isNull: false,
		}
	},

	methods: {
		async handleLoadData(page = 1, limit = 10) {
			const me = this
			try {
				if (Object.prototype.hasOwnProperty.call(me, 'isBusy')) {
					me.isBusy = true
				}
				console.log(limit)
				const result = await me[me.repository].getAll(page, limit, me.isNull)
				me.paginationServe = result
				console.log(result)
				if (Object.prototype.hasOwnProperty.call(me, 'isBusy')) {
					me.isBusy = false
				}
				me.records = result.data
			} catch {
				me.records = []
			} finally {
				if (Object.prototype.hasOwnProperty.call(me, 'isBusy')) {
					me.isBusy = false
				}
			}
		},

		async handleLoadData2(page = 1, limit = 10) {
			const me = this
			me.records = []
			try {
				if (Object.prototype.hasOwnProperty.call(me, 'isBusy')) {
					me.isBusy = true
				}
				const result = await me[me.repository].getAll(me.customer_id)
				me.paginationServe = result
				if (Object.prototype.hasOwnProperty.call(me, 'isBusy')) {
					me.isBusy = false
				}
				me.records = result
			} catch {
				me.records = []
			} finally {
				if (Object.prototype.hasOwnProperty.call(me, 'isBusy')) {
					me.isBusy = false
				}
			}
		},

		async save() {
			const me = this
			me.$refs.form.visible = false
			me.$bvModal.hide(`${me.module}-modal`)
			me.$bvToast.toast('Registro guardado con éxito', {
				title: 'Éxito',
				toaster: 'b-toaster-bottom-right',
				variant: 'success',
				solid: true,
				autoHideDelay: 3000,
			})
			await me.handleLoadData()
		},

		async edit() {
			await this.handleLoadData()
		},

		del(id) {
			const me = this
			this.$bvModal
				.msgBoxConfirm('¿Está seguro que desea eliminar el registro?', {
					title: 'Eliminar registro',
					size: 'sm',
					okVariant: 'danger',
					okTitle: 'Eliminar',
					buttonSize: 'sm',
					hideHeaderClose: false,
					cancelTitle: 'Cancelar',
					cancelVariant: 'outline-secondary',
					centered: true,
					modalClass: 'modal-danger',
				})
				.then(async value => {
					if (value) {
						if (Object.prototype.hasOwnProperty.call(me, 'isBusy')) {
							me.isProcessing = true
						}
						try {
							await me[me.repository].delete(id)
							if (Object.prototype.hasOwnProperty.call(me, 'isBusy')) {
								me.isProcessing = false
							}
							await me.handleLoadData()
						} catch (e) {
							console.error(e)
							me.$bvToast.toast('Error', {
								title: 'Ocurrió un error al eliminar el registro',
								toaster: 'b-toaster-bottom-right',
								variant: 'danger',
								solid: true,
								autoHideDelay: 3000,
							})
						} finally {
							if (Object.prototype.hasOwnProperty.call(me, 'isBusy')) {
								me.isProcessing = false
							}
						}
					}
				})
		},

		async saveData() {
			const me = this

			try {
				const data = Object.prototype.hasOwnProperty.call(me, 'prepareDataForSubmit')
					? me.prepareDataForSubmit()
					: {
						...me.formData,
					}
				const id = me.record ? me.record.id : uuidv4()
				const option = me.record ? 'update' : 'save'
				if (Object.prototype.hasOwnProperty.call(me, 'isBusy')) {
					me.isBusy = true
				}
				await me[me.repository][option](id, data)
				if (Object.prototype.hasOwnProperty.call(me, 'isBusy')) {
					me.isBusy = false
				}
				me.$emit('save')
			} catch (e) {
				const { errors, error } = e.response.data
				let message = ''

				if (errors?.email) {
					message = 'Email ya se encuentra registrado'
				} else if (errors?.nid) {
					message = 'Nº de Identificación ya se encuentra registrado'
				} else if (error) {
					message = error
				}

				me.$bvToast.toast(message, {
					title: 'Ocurrió un error al guardar el registro',
					toaster: 'b-toaster-bottom-right',
					variant: 'danger',
					solid: true,
					autoHideDelay: 3000,
				})
			} finally {
				if (Object.prototype.hasOwnProperty.call(me, 'isBusy')) {
					me.isBusy = false
				}
			}
		},
	},
}
