<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card-title>TIPOS DE DISPOSITIVOS</b-card-title>
      </b-col>
    </b-row>
    <select-filter
      :records="records"
      @loadData="loadData"
    />

    <apexchart
      v-if="records2.length > 0"
      width="900"
      height="300"
      type="bar"
      :options="options"
      :series="series"
    />

    <b-row class="mb-2">
      <b-col cols="12">
        <location-table
          :records="records2"
          :busy="isBusy"
          :is-disabled="isDisabled"
          :module="module"
          :search="search"
          @creating="handleCreating"
          @editing="handleEditing"
          @detail="handleDetail"
          @deleting="handleDeleting"
        />
      </b-col>
    </b-row>

    <b-modal
      id="modal-associate-device"
      centered
      title="Asociar dispositivo"
      hide-footer
      size="lg"
    >
      <form-associate-device
        :record="record"
        :brand-id="brandId"
        :brand-name="brandName"
        :is-disabled="isDisabled"
        :is-edit="isEdit"
        @loadData="loadData"
      />
    </b-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

import {
	BCardTitle,
	BRow,
	BCol,
  BModal,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import FormAssociateDevice from './FormAssociateDevice'
import LocationTable from './LocationTable'
import SelectFilter from './SelectFilter'
import PermissionMixin from '@/mixins/PermissionMixin'

export default {
  inject: ['devicesRepository'],
	directives: {
		Ripple,
	},
	components: {
		BCardTitle,
		BRow,
		BCol,
    BModal,
		apexchart: VueApexCharts,
    SelectFilter,
    LocationTable,
    FormAssociateDevice,
	},
  mixins: [PermissionMixin],
  props: {
    records: {
      type: Array,
      default: () => [],
    },
  },
	data() {
		return {
			options: {
				chart: {
					id: 'vuechart-example',
          events: {
            dataPointSelection: (event, chartContext, config) => {
							this.handleSearch(config.dataPointIndex, config.w.config.xaxis.categories)
            },
          },
				},
				xaxis: {
					categories: [],
				},
			},
			series: [{
        name: 'Cantidad',
        data: [],
      }],
      records2: [],
      record: null,
      module: 'deviceBrand',
      isBusy: false,
      isDisabled: true,
      isEdit: false,
      brandId: null,
      brandName: null,
      search: null,
		}
	},
	methods: {
    handleCreating() {
      const me = this
      me.record = null
      me.isEdit = true
      me.isDisabled = false
			me.$bvModal.show('modal-associate-device')
    },
    async loadData(id, name) {
      const me = this
      me.brandId = id
      me.brandName = name
      me.isDisabled = false
      try {
        me.isBusy = true
        me.records2 = []
        const { data } = await me.devicesRepository.getAll(id)
        me.records2 = data
        me.fillGraphic(me.records2)
      } catch (error) {
        // console.log(error)
      } finally {
        me.isBusy = false
      }
    },
    handleEditing(item) {
      const me = this
      me.record = item
      me.isEdit = false
      me.isDisabled = false
			me.$bvModal.show('modal-associate-device')
    },
    handleDeleting(item) {
			const me = this
			this.$bvModal
				.msgBoxConfirm('¿Está seguro que desea eliminar el registro?', {
					title: 'Eliminar registro',
					size: 'sm',
					okVariant: 'danger',
					okTitle: 'Eliminar',
					buttonSize: 'sm',
					hideHeaderClose: false,
					cancelTitle: 'Cancelar',
					cancelVariant: 'outline-secondary',
					centered: true,
					modalClass: 'modal-danger',
				})
				.then(async value => {
					if (value) {
            me.isBusy = true
						await me.devicesRepository.delete(item.subarea_id, item.id)
					}
				})
		},
    handleDetail(item) {
      const me = this
      me.record = item
      me.isDisabled = true
      me.isEdit = false
			me.$bvModal.show('modal-associate-device')
    },
    fillGraphic(records) {
      const me = this
      const devices = []
      me.options.xaxis.categories = []
      me.series[0].data = []
      records.forEach(el => {
        const device = devices.find(dev => dev.name == el.device_type_name)

        if (device) {
          device.data.push(device.name)
        } else {
          devices.push({
            name: el.device_type_name,
            data: [el.device_type_name],
          })
        }
      })

      devices.forEach(x => {
        me.options.xaxis.categories.push(x.name)
        me.series[0].data.push(x.data.length)
      })
    },
    handleSearch(index, categories) {
      const me = this
      me.search = categories[index]
		},
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/map-leaflet.scss';
.iconos{
  margin-right: -2px;
  display: inline-block;
  padding: -9px;
}
</style>
