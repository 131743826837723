<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Nombre del contacto*"
          label-for="contact"
        >
          <b-form-input
            id="contact"
            v-model="formData.contact"
            placeholder="Nombre del contacto"
            @blur="$v.formData.contact.$touch()"
          />
          <span
            v-if="$v.formData.contact.$error && !$v.formData.contact.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Cargo*"
          label-for="post"
        >
          <b-form-input
            id="post"
            v-model="formData.post"
            placeholder="Cargo"
            @blur="$v.formData.post.$touch()"
          />
          <span
            v-if="$v.formData.post.$error && !$v.formData.post.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Correo electrónico*"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="formData.email"
            type="email"
            placeholder="Correo electrónico"
            @blur="$v.formData.email.$touch()"
          />
          <span
            v-if="$v.formData.email.$error && !$v.formData.email.required"
            class="help-block text-danger"
          >Campo requerido</span>
          <span
            v-if="$v.formData.email.$error && !$v.formData.email.email"
            class="help-block text-danger"
          >Ingrese una dirección de correo válida.</span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Teléfono*"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            v-model="formData.phone"
            placeholder="Teléfono"
            @blur="$v.formData.phone.$touch()"
          />
          <span
            v-if="$v.formData.phone.$error && !$v.formData.phone.required"
            class="help-block text-danger"
          >Campo requerido</span>
          <span
            v-if="$v.formData.phone.$error && !$v.formData.phone.numeric"
            class="help-block text-danger"
          >Ingrese solo números.</span>
          <span
            v-if="$v.formData.phone.$error && !$v.formData.phone.minLength"
            class="help-block text-danger"
          >
            El teléfono debe tener mínimo
            {{ $v.formData.phone.$params.minLength.min }} dígitos.
          </span>
          <span
            v-if="$v.formData.phone.$error && !$v.formData.phone.maxLength"
            class="help-block text-danger"
          >
            El teléfono debe tener máximo
            {{ $v.formData.phone.$params.maxLength.max }} dígitos.
          </span>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Celular*"
          label-for="cellphone"
        >
          <b-form-input
            id="cellphone"
            v-model="formData.cellphone"
            placeholder="Celular"
            @blur="$v.formData.cellphone.$touch()"
          />
          <span
            v-if="$v.formData.cellphone.$error && !$v.formData.cellphone.required"
            class="help-block text-danger"
          >Campo requerido</span>
          <span
            v-if="$v.formData.cellphone.$error && !$v.formData.cellphone.numeric"
            class="help-block text-danger"
          >Ingrese solo números.</span>
          <span
            v-if="
              $v.formData.cellphone.$error && !$v.formData.cellphone.minLength
            "
            class="help-block text-danger"
          >
            El celular debe tener mínimo
            {{ $v.formData.cellphone.$params.minLength.min }} dígitos.
          </span>
          <span
            v-if="
              $v.formData.cellphone.$error && !$v.formData.cellphone.maxLength
            "
            class="help-block text-danger"
          >
            El celular debe tener máximo
            {{ $v.formData.cellphone.$params.maxLength.max }} dígitos.
          </span>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup,
  BCol,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import {
 required, email, numeric, minLength,
	maxLength,
} from 'vuelidate/lib/validators'

export default {
  components: {
    BFormGroup,
    BCol,
    BFormInput,
    BRow,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        phone: '',
        contact: '',
        cellphone: '',
        email: '',
        post: '',
      },
    }
  },
  watch: {
    record(newData) {
      const me = this
      me.formData = { ...me.formData, ...newData }
    },
  },
  validations: {
    formData: {
      phone: {
        required,
        numeric,
        minLength: minLength(7),
				maxLength: maxLength(15),
      },
      contact: {
        required,
      },
      cellphone: {
        required,
        numeric,
        minLength: minLength(7),
				maxLength: maxLength(15),
      },
      email: {
        required,
        email,
      },
      post: {
        required,
      },
    },
  },
  methods: {
    validate() {
      const me = this
      me.$v.formData.$touch()
      const isValid = !me.$v.formData.$invalid

      me.$emit('on-validate', me.formData, isValid)
      return isValid
    },
  },
}
</script>

<style>

</style>
