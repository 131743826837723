<template>
  <b-modal
    id="modal-crear-headquarters"
    centered
    :title="title"
    size="lg"
  >
    <form-wizard-headquarter
      :customer-id="customerId"
      :record="record"
      @loadHeadquarters="loadHeadquarters"
    />
    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        v-b-modal.modal-seconday
        variant="outline-secondary"
        @click="close()"
      >
        Cerrar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FormWizardHeadquarter from './FormWizardHeadquarter'

export default {
  components: {
    BModal,
    BButton,
    FormWizardHeadquarter,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    customerId: {
      type: String,
      default: null,
    },
    record: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {}
  },
  methods: {
    loadHeadquarters() {
      this.$emit('loadHeadquarters')
    },
  },
}
</script>

<style lang="scss"></style>
