<template>
  <b-row>
    <b-col
      v-if="isEdit"
      md="6"
    >
      <b-form-group
        label="Sede"
      >
        <b-form-input
          id="mac"
          v-model="brandName"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col
      v-if="isEdit"
      md="6"
    >
      <b-form-group
        label="Servicio*"
        label-for="location_type_id"
      >
        <v-select
          id="location_type_id"
          label="name"
          placeholder="Seleccione servicio"
          class="w-100"
          :options="services"
          :reduce="(option) => option.id"
          :disabled="isDisabled"
          @input="loadAreas"
        >
          <div slot="no-options">
            No hay opciones
          </div>
        </v-select>
      </b-form-group>
    </b-col>
    <b-col
      v-if="isEdit"
      md="6"
    >
      <b-form-group
        label="Area*"
        label-for="location_type_id"
      >
        <v-select
          id="location_type_id"
          label="name"
          placeholder="Seleccione area"
          class="w-100"
          :options="areas"
          :reduce="(option) => option.id"
          :disabled="isDisabled"
          @input="loadSubareas"
        >
          <div slot="no-options">
            No hay opciones
          </div>
        </v-select>
      </b-form-group>
    </b-col>
    <b-col
      v-if="isEdit"
      md="6"
    >
      <b-form-group
        label="Subarea*"
        label-for="location_type_id"
      >
        <v-select
          id="location_type_id"
          v-model="subareaId"
          label="name"
          placeholder="Seleccione subarea"
          class="w-100"
          :options="subareas"
          :reduce="(option) => option.id"
          :disabled="isDisabled"
        >
          <div slot="no-options">
            No hay opciones
          </div>
        </v-select>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        label="Nombre*"
        label-for="name"
      >
        <b-form-input
          id="name"
          v-model="formData.name"
          placeholder="Nombre"
          :disabled="isDisabled"
        />
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        label="Tipo de ubicación*"
        label-for="location_type_id"
      >
        <v-select
          id="location_type_id"
          v-model="formData.location_type_id"
          label="name"
          placeholder="Seleccione tipo"
          class="w-100"
          :options="locationTypes"
          :reduce="(option) => option.id"
          :disabled="isDisabled"
        >
          <div slot="no-options">
            No hay opciones
          </div>
        </v-select>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        label="Tipo de dispositivo*"
        label-for="device_type_id"
      >
        <v-select
          id="device_type_id"
          v-model="formData.device_type_id"
          label="name"
          placeholder="Seleccione tipo"
          class="w-100"
          :options="deviceTypes"
          :reduce="(option) => option.id"
          :disabled="isDisabled"
        />
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        label="MAC*"
        label-for="mac"
      >
        <b-form-input
          id="mac"
          v-model="formData.mac"
          placeholder="MAC"
          :disabled="isDisabled"
        />
      </b-form-group>
    </b-col>
    <b-col
      v-if="!isDisabled"
      cols="12"
      class="text-center"
    >
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="primary"
        :disabled="isBusy || $v.$invalid"
        @click="saveData"
      >
        <b-spinner
          v-if="isBusy"
          small
        />
        <v-icon
          v-else
          name="save"
        />
        Guardar
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
	BButton,
	BCol,
	BRow,
	BFormGroup,
	BFormInput,
	BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'

export default {
	inject: [
		'locationsRepository',
		'devicesRepository',
		'servicesRepository',
		'areasRepository',
    'subAreasRepository',
	],
	components: {
		BButton,
		BCol,
		BRow,
		BFormGroup,
		BFormInput,
		BSpinner,
		vSelect,
	},
	directives: {
		Ripple,
	},
	props: {
		record: {
			type: Object,
			default: null,
		},
		brandId: {
			type: String,
			default: null,
		},
    brandName: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
	},
	data() {
		return {
			formData: {
				name: '',
				device_type_id: null,
				mac: '',
				location_type_id: '',
			},
			locationTypes: [],
			deviceTypes: [],
			services: [],
			areas: [],
			subareas: [],
      subareaId: null,
      isBusy: false,
		}
	},
	validations: {
		formData: {
			name: {
				required,
			},
			location_type_id: {
				required,
			},
			device_type_id: {
				required,
			},
			mac: {
				required,
			},
		},
	},
	async mounted() {
		const me = this
    await me.loadService()
		await me.loadLocationType()
		await me.loadDeviceType()
		if (me.record) {
      me.formData = {
        id: me.record.id,
        name: me.record.location_name,
        mac: me.record.mac,
        location_type_id: me.record.location_id,
        device_type_id: me.record.device_type_id,
      }
    }
	},
	methods: {
    async loadService() {
      const me = this
      try {
        me.services = []
        const { data } = await me.servicesRepository.getAll(me.brandId)
        me.services = data
      } catch (error) {
        // console.log(error)
      }
    },
    async loadAreas(id) {
      const me = this
      try {
        me.areas = []
        const { data } = await me.areasRepository.getAll(id)
        me.areas = data
      } catch (error) {
        // console.log(error)
      }
    },
    async loadSubareas(id) {
      const me = this
      try {
        me.subareas = []
        const { data } = await me.subAreasRepository.getAll(id)
        me.subareas = data
      } catch (error) {
        // console.log(error)
      }
    },
		async loadLocationType() {
			const me = this
			try {
				const res = await me.locationsRepository.getLocationTypeAll()
				me.locationTypes = res.data
			} catch (error) {
				// console.log(error)
			}
		},
		async loadDeviceType() {
			const me = this
			try {
				const res = await me.devicesRepository.getDeviceTypeAll()
				me.deviceTypes = res.data
			} catch (error) {
				// console.log(error)
			}
		},
		async saveData() {
			const me = this
      try {
        me.isBusy = true
        const uuid = me.formData.id ? me.formData.id : uuidv4()
        const option = me.formData.id ? 'update' : 'save'

        const data = {
          name: me.formData.name,
          location_type_id: me.formData.location_type_id,
        }

        await me.locationsRepository[option](
          me.subareaId,
          uuid,
          data,
        )

        const device = {
          mac: me.formData.mac,
          device_type_id: me.formData.device_type_id,
          location_id: uuid,
          brand_id: me.brandId,
          customer_id: me.customerId,
          status: '1',
        }

        await me.devicesRepository[option](
          uuid,
          device,
        )
        me.$bvModal.hide('modal-associate-device')
        me.$emit('loadData', me.brandId)
				me.$bvToast.toast('Registro guardado con éxito', {
					title: 'Éxito',
					toaster: 'b-toaster-bottom-right',
					variant: 'success',
					solid: true,
					appendToast: true,
				})
      } catch (error) {
        me.$bvToast.toast('Registro no guardado.', {
					title: 'Ocurrió un error al guardar el registro',
					toaster: 'b-toaster-bottom-right',
					variant: 'danger',
					solid: true,
					appendToast: true,
				})
        // console.log(error)
      } finally {
        me.isBusy = false
      }
		},
	},
}
</script>

<style lang="scss"></style>
