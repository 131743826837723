<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      class="wizard-vertical"
    >
      <!-- Datos de la sede tab -->
      <tab-content
        title="Datos de la sede"
        :before-change="() => validateStep('step1')"
      >
        <location-data-form
          ref="step1"
          :record="record"
          @on-validate="locationData"
        />
      </tab-content>

      <!-- Contacto tab -->
      <tab-content
        title="Contacto"
        :before-change="() => validateStep('step2')"
      >
        <contact-headquarters-form
          ref="step2"
          :record="contactRecord"
          @on-validate="contactData"
        />
      </tab-content>
      <template
        slot="footer"
        scope="props"
      >
        <div class="wizard-footer-left">
          <wizard-button
            v-if="props.activeTabIndex > 0 && !props.isLastStep"
            :style="props.fillButtonStyle"
            @click.native="props.prevTab()"
          >
            Atràs
          </wizard-button>
        </div>
        <div class="wizard-footer-right">
          <wizard-button
            v-if="!props.isLastStep"
            class="wizard-footer-right"
            :style="props.fillButtonStyle"
            @click.native="props.nextTab()"
          >
            Siguiente
          </wizard-button>

          <wizard-button
            v-else
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            @click.native="validateStep('step2')"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="save"
            />
            {{ props.isLastStep ? 'Guardar' : 'Siguiente' }}
          </wizard-button>
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BSpinner } from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import LocationDataForm from './LocationDataForm'
import ContactHeadquartersForm from './ContactHeadquartersForm'
/* import CRUDMixin from '@/mixins/CRUDMixin' */

export default {
	inject: ['headquarterRepository'],
	components: {
		FormWizard,
		TabContent,
		WizardButton,
		BSpinner,
		LocationDataForm,
		ContactHeadquartersForm,
	},
	props: {
		customerId: {
      type: String,
      default: null,
    },
		record: {
      type: Object,
      default: null,
    },
	},
	data() {
		return {
			formData: {},
			isBusy: false,
			uuid: this.$route.params.uuid ? this.$route.params.uuid : null,
      contactRecord: null,
		}
	},
  mounted() {
    const me = this
    if (me.record) {
      me.contactRecord = {
        phone: me.record.phone,
        contact: me.record.contact,
        cellphone: me.record.cellphone,
        email: me.record.email,
        post: me.record.post,
      }
    }
  },
	methods: {
		validateStep(name) {
			const refToValidate = this.$refs[name]
			return refToValidate.validate()
		},
		locationData(model, isValid) {
			const me = this
			if (isValid) {
				me.formData = { ...me.formData, ...model }
			}
		},
		contactData(model, isValid) {
			const me = this
			if (isValid) {
				me.formData = { ...me.formData, ...model }
				me.formSubmitted()
			}
		},
		async formSubmitted() {
			const me = this
			try {
				me.isBusy = true
				const uuid = me.formData.id ? me.formData.id : uuidv4()
        const option = me.formData.id ? 'update' : 'save'
				await me.headquarterRepository[option](me.customerId, uuid, me.formData)
				me.$emit('loadHeadquarters')
				this.$bvModal.hide('modal-crear-headquarters')
				me.$bvToast.toast('Registro guardado con éxito', {
					title: 'Éxito',
					toaster: 'b-toaster-bottom-right',
					variant: 'success',
					solid: true,
					appendToast: true,
				})
			} catch (err) {
				const { errors } = err.response.data
				if (errors.email) {
					me.$bvToast.toast('Correo electrónico ya esta en uso.', {
						title: 'Ocurrió un error al guardar el registro',
						toaster: 'b-toaster-bottom-right',
						variant: 'danger',
						solid: true,
						appendToast: true,
					})
				}
			} finally {
				me.isBusy = false
			}
		},
		isLastStep() {
			if (this.$refs.wizard) {
				return this.$refs.wizard.isLastStep
			}
			return false
		},
	},
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
