<template>
  <div>
    <b-row>
      <div
        class="container container_ d-flex align-items-center"
      >
        <b-col cols="9">
          <div class="p-2">
            <label
              class="title"
            >{{ item.name }} ({{ item.is_principal == true ? 'Principal' : 'Secundaria' }})</label>
          </div>
        </b-col>
        <b-col
          cols="3"
        >
          <b-button
            variant="success"
            size="sm "
            class="bg-daren-2 btn_register"
            @click="create(item.id)"
          ><feather-icon
            icon="PlusIcon"
          />Registrar Servicio
          </b-button>
        </b-col>
      </div>
      <b-table
        striped
        hover
        responsive
        :items="item.Service"
        :fields="fields"
        :busy="busy"
        show-empty
        class="mb-1 text-center"
        tbody-tr-class="cursor-pointer"
        @row-clicked="redirectArea"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Cargando datos...</strong>
          </div>
        </template>
        <template #empty>
          <h4 class="text-center py-2">
            No hay registros
          </h4>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <b-button
              v-b-tooltip.hover.top="'Editar Servicio'"
              variant="primary"
              size="sm"
              class=" btn-icon mr-25"
              @click="edit(data.item)"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>
            <b-button
              v-b-tooltip.hover.top="'Eliminar Servicio'"
              variant="danger"
              size="sm"
              class=" btn-icon"
              @click="deleteItem(data.item)"
            >
              <feather-icon
                icon="Trash2Icon"
              />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
	BTable,
	BRow,
	BButton,
  BCol,
	VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
		BTable,
		BRow,
		BButton,
    BCol,
	},
  directives: {
		Ripple,
    'b-tooltip': VBTooltip,
	},
  props: {
    item: {
      type: Object,
      default: null,
    },
    busy: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      fields: [
				{
					key: 'service_name',
					label: 'Servicio',
				},
				{
					key: 'area_quantity',
					label: 'Área',

				},
				{
					key: 'subarea_quantity',
					label: 'Subarea',
				},
				{
					key: 'device_quantity',
					label: 'Dispositivos Asociados',
				},
				{
					key: 'actions',
					label: 'Acciones',
				},
			],
    }
  },
  methods: {
    redirectArea(item) {
      this.$emit('redirectArea', item)
    },
    create(id) {
      this.$emit('create', id)
    },
    edit(item) {
      this.$emit('edit', item)
    },
    deleteItem(item) {
      this.$emit('deleteItem', item)
    },
  },
}
</script>

<style lang="scss" scoped>
  .title{
    color: white;
    font-weight: bold;
    font-size: 18px;
  }

  .container_ {
    background-color: rgb(105, 105, 107);
    padding: 0px;
  }

  .btn_register {
    font-weight: bold;
    padding: 10px;
    font-size: 16px;
  }
</style>
