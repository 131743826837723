<template>
  <b-card
    no-body
    class="p-1"
  >
    <b-tabs
      pills
      justified
    >
      <!--Empresas-->
      <b-tab
        title="Empresa"
        active
      >
        <companyDetail :record="record" />
      </b-tab>

      <!--Sedes-->
      <b-tab title="Sedes">
        <headquarters
          :records="records"
          :customer-id="getLoggedUser.customer_id"
          @loadHeadquarters="handleLoadData2"
        />
      </b-tab>

      <!--Servicios-->
      <b-tab title="Servicios">
        <service
          :records="records"
          :customer-id="getLoggedUser.customer_id"
        />
      </b-tab>

      <!--Dispositivos-->
      <b-tab title="Dispositivos">
        <device :records="records" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
	BCard,
	BTabs,
	BTab,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import companyDetail from './companyDetail'
import Headquarters from './Headquarters/Headquarters'
import service from './Services/Service'
import device from './Devices/Device.vue'
import CRUDMixin from '@/mixins/CRUDMixin'

export default {
  inject: ['companiesRepository', 'headquarterRepository'],
	components: {
		BCard,
		BTabs,
		BTab,
		companyDetail,
		Headquarters,
		service,
    device,
	},
  mixins: [CRUDMixin],
  data() {
		return {
			record: null,
      records: [],
      isBusy: false,
      repository: 'headquarterRepository',
		}
	},
  computed: {
    ...mapGetters('session', ['getLoggedUser']),
  },
  async mounted() {
    const me = this
    await me.loadClient()
    await me.handleLoadData2()
	},
  methods: {
    async loadClient() {
      const me = this
      try {
        const res = await me.companiesRepository.find(me.getLoggedUser.customer_id)
        me.record = {
          ...res,
          ...res.brand,
          name_company: res.name,
          name_brand: res.brand ? res.brand.name : null,
        }
      } catch (error) {
        // console.log(error)
      }
    },
  },
}
</script>
<style lang="scss">
  .nav.nav-pills.nav-justified {
    border: 4px solid #7367f0 !important;
    padding: 15px !important;
    border-radius: 0.6rem !important;
  }
</style>
