<template>
  <b-row>
    <b-col cols="12">
      <b-card-title class="mb-0">
        SEDES DE LA EMPRESA
      </b-card-title>
    </b-col>

    <b-card no-body>
      <base-actions-table
        :columns="fields"
        :records="records"
        :is-processing="isProcessing"
        :busy="isBusy"
        :module="module"
        @creating="handleCreating"
        @editing="handleEditing"
        @deleting="handleDeleting"
      >
        <template #cell(name)="data">
          <span>{{ data.item.name }} ({{
            data.item.is_principal == true ? 'Principal' : 'Secundaria'
          }})</span>
        </template>
      </base-actions-table>
    </b-card>

    <!-- Info Brand modal -->
    <b-modal
      :id="infoModalBrand.id"
      title="Configuración Rol de Usuario"
      size="lg"
      ok-only
      cancel-variant="outline-secondary"
      cancel-title="Close"
      ok-title="Guardar"
      centered
      @hide="resetInfoModalBrand"
    >
      <b-form @submit="onSubmit">
        <b-row>
          <b-col cols="4">
            <b-form-group>
              <label for="username">Usuario:</label>
              <b-form-input
                id="username"
                type="text"
                placeholder="Usuario"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group>
              <label for="password">Contraseña:</label>
              <b-form-input
                id="password"
                type="text"
                placeholder="Contraseña"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group>
              <label for="username">Confirmar Contraseña:</label>
              <b-form-input
                id="confirmPassword"
                type="text"
                placeholder="Confirmar contraseña"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <label
                for="numbreUserAllow"
              >Número de usuarios permitidos:</label>
              <b-form-input
                id="numbreUserAllow"
                type="text"
                placeholder="Número de usuarios permitidos"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label for="numberBrandAllow">Número de sedes permitidas:</label>
              <b-form-input
                id="numberBrandAllow"
                type="text"
                placeholder="Número de sedes permitidas"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="h-divider" />
        <b-row>
          <b-col cols="6">
            <b-form-group
              id="input-group-4"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox-group
                id="checkboxes-4"
                v-model="form.checked"
                :aria-describedby="ariaDescribedby"
              >
                <b-form-checkbox value="diet">
                  Módulo Gestión dieta
                </b-form-checkbox>
                <b-form-checkbox value="patient">
                  Módulo Gestión paciente
                </b-form-checkbox>
                <b-form-checkbox value="humanResources">
                  Módulo Gestión de recursos humanos
                </b-form-checkbox>
                <b-form-checkbox value="medicines">
                  Módulo Gestión de medicamentos
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <create-edit-headquarters-modal
      :customer-id="customerId"
      :record="record"
      :title="title"
      @loadHeadquarters="loadHeadquarters"
    />
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
	BCard,
	BCardTitle,
	BRow,
	BCol,
	BFormGroup,
	BFormInput,
	BModal,
	BForm,
	BFormCheckboxGroup,
	BFormCheckbox,
} from 'bootstrap-vue'
import BaseActionsTable from '@/components/base/BaseActionsTable'
import CreateEditHeadquartersModal from '../components/CreateEditHeadquartersModal'
import CRUDMixin from '@/mixins/CRUDMixin'

export default {
	inject: ['headquarterRepository'],
	directives: {
		Ripple,
	},
	components: {
		BCard,
		BCardTitle,
		BRow,
		BCol,
		BFormGroup,
		BFormInput,
		BModal,
		BForm,
		BFormCheckboxGroup,
		BFormCheckbox,
		BaseActionsTable,
		CreateEditHeadquartersModal,
	},
	mixins: [CRUDMixin],
	props: {
		records: {
			type: Array,
			default: null,
		},
		customerId: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			infoModalBrand: {
				id: 'info--brand-modal',
				title: '',
				content: '',
			},
			fields: [
				{
					key: 'name',
					label: 'Nombre',
				},
				{
					key: 'address',
					label: 'Dirección',
				},
				{
					key: 'contact',
					label: 'Contacto',
				},
				{
					key: 'celphone',
					label: 'Celular',
				},
				{
					key: 'email',
					label: 'Correo',
				},
				{
					key: 'actions',
					label: 'Acciones',
				},
			],
      module: 'headquarter',
			record: null,
			isBusy: false,
      title: 'Nueva sede',
		}
	},
	methods: {
		infoBrand(item, index, button) {
			this.infoModalBrand.title = `Row index: ${index}`
			this.infoModalBrand.content = JSON.stringify(item, null, 2)
			this.$root.$emit('bv::show::modal', this.infoModalBrand.id, button)
		},
		resetInfoModalBrand() {
			this.infoModalBrand.title = ''
			this.infoModalBrand.content = ''
		},
		onSubmit(event) {
			event.preventDefault()
			alert(JSON.stringify(this.form))
		},
		handleCreating() {
      const me = this
      me.record = null
      me.title = 'Nueva sede'
			this.$bvModal.show('modal-crear-headquarters')
		},

		handleEditing(record) {
      const me = this
      me.title = 'Actualizar sede'
			me.record = { ...record, cellphone: record.celphone }
			me.$bvModal.show('modal-crear-headquarters')
		},

		handleDeleting(record) {
			const me = this
			this.$bvModal
				.msgBoxConfirm('¿Está seguro que desea eliminar el registro?', {
					title: 'Eliminar registro',
					size: 'sm',
					okVariant: 'danger',
					okTitle: 'Eliminar',
					buttonSize: 'sm',
					hideHeaderClose: false,
					cancelTitle: 'Cancelar',
					cancelVariant: 'outline-secondary',
					centered: true,
					modalClass: 'modal-danger',
				})
				.then(async value => {
					if (value) {
						me.isBusy = true
						await me.headquarterRepository.delete(me.customerId, record.id)
						me.loadHeadquarters()
						me.isBusy = false
					}
				})
		},

		loadHeadquarters() {
      this.$emit('loadHeadquarters')
    },
	},
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
</style>
