<template>
  <div>
    <b-row>
      <b-col
        md="6"
        class="mb-1"
      >
        <div class="d-flex align-items-center">
          <div class="title_ mr-1">
            Sede
          </div>
          <v-select
            label="name"
            :options="records"
            class="select"
            placeholder="Seleccione sede"
            @input="loadData"
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    vSelect,
  },
  props: {
    records: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  methods: {
    loadData(item) {
      this.$emit('loadData', item.id, item.name)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .select {
    width: 100%;
  }
  .title_ {
    font-size: 14px;
    color: #636363;
    font-weight: bold;
  }
</style>
